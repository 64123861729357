.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}


.App-header {
  background-color: #ffffff;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(8px + 1vmin);
  color: #0e0e0e;
}

.App-link {
  color: #ffffff;
}

.centered-paragraph {
  max-width: 50vw;
  text-align: center;
  margin: 0 auto;
}

h3 {
  font-family: "PT Serif";
  padding: 0;
  margin: 1vh auto;
}

.box-boarder {
  background-color: #ffffff;
  padding: 1vh;
  border: 2px solid #000000;
  border-radius: 4px;
}

.footer {
  position: fixed;
  left: 0;
  bottom: 0;
  width: 100%;
  text-align: center;
  padding: 10px;
  background-color: #f8f9fa; /* optional: add a background color to the footer */
}
